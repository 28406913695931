import React from 'react';
import PropTypes from 'prop-types';

export const IconWithdrawal = ({ className }) => (
  <svg className={className} width="138" height="136" viewBox="0 0 138 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#icon-withdrawal-filter)">
      <path d="M76.2656 37H61.7344C60.7304 37 59.918 37.8124 59.918 38.8164C59.918 39.8204 60.7304 40.6328 61.7344 40.6328H67.1836V46.3242C67.1836 47.3282 67.996 48.1406 69 48.1406C70.004 48.1406 70.8164 47.3282 70.8164 46.3242V40.6328H76.2656C77.2696 40.6328 78.082 39.8204 78.082 38.8164C78.082 37.8124 77.2696 37 76.2656 37ZM93.392 47.3619C92.6825 46.6524 91.533 46.6524 90.8235 47.3619L85.6971 52.4883C84.9876 53.1977 84.9876 54.3473 85.6971 55.0568C86.0519 55.4116 86.5167 55.589 86.9813 55.589C87.446 55.589 87.9108 55.4116 88.2655 55.0568L93.3919 49.9304C94.1015 49.2209 94.1015 48.0715 93.392 47.3619Z" fill="url(#icon-withdrawal-1)" />
      <path d="M69 99C53.9757 99 41.7539 86.7783 41.7539 71.7539C41.7539 56.7296 53.9757 44.5078 69 44.5078C84.0243 44.5078 96.2461 56.7296 96.2461 71.7539C96.2461 86.7783 84.0243 99 69 99Z" fill="url(#icon-withdrawal-2)" />
      <path d="M81.8373 86.4076C81.3725 86.4076 80.9078 86.2302 80.5531 85.8754L67.7158 73.0381C67.3752 72.6976 67.1836 72.2363 67.1836 71.7539V53.5898C67.1836 52.5859 67.996 51.7734 69 51.7734C71.4444 51.7734 73.8355 52.2134 76.106 53.0789C83.8063 56.0183 88.9805 63.5233 88.9805 71.7539C88.9805 77.0843 86.8997 82.0989 83.1215 85.8754C82.7668 86.2302 82.302 86.4076 81.8373 86.4076Z" fill="url(#icon-withdrawal-3)" />
    </g>
  </svg>
);

IconWithdrawal.propTypes = {
  className: PropTypes.string
};

IconWithdrawal.defaultProps = {
  className: ''
};
