import React, { Fragment } from 'react';
import { TOURNAMENT_STATUSES } from 'core/constants';

import { AllGames } from 'components/all-games/all-games';
import { PromoPwaInstall } from 'components/promo-pwa-install/promo-pwa-install';
import { PromoTournaments } from 'components/promo-tournaments/promo-tournaments';
import { MostPopularGames } from 'components/most-popular-games/most-popular-games';
import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { NavigationBanners } from 'components/navigation-banners/navigation-banners';
import { EnjoyExcellentService } from 'components/enjoy-excellent-service/enjoy-excellent-service';
import { ProducersPromo } from 'components/producers-promo/producers-promo';

// import { Producers } from 'components/producers/producers';
// import { PaymentsPromo } from 'components/payments-promo/payments-promo';
// import { LivechatStats } from 'components/livechat-stats/livechat-stats';
// import { NewComersBanner } from 'components/new-comers-banner/new-comers-banner';
// import { RecommendedGames } from 'components/recommended-games/recommended-games';
// import { NewestGames } from 'components/newest-games/newest-games';
// import { GameTags } from 'components/game-tags/game-tags';
// import { GamesCategories } from 'components/games-categories/games-categories';
// import { RecentlyPlayedGames } from 'components/recently-played-games/recently-played-games';
// import { SportsQuickLinks } from 'src/components/sports-quick-links/sports-quick-links';

const ALL_GAMES_MOBILE_GAMES_COUNT = 16;
const ALL_GAMES_DESKTOP_GAMES_COUNT = 35;

const TOP_PRODUCERS = [
  'Evolution',
  'Microgaming',
  'Netent',
  'Yggdrasil',
  'Quickspin'
];

export const NotLoggedIn = () => (
  <Fragment>
    <NavigationBanners className="mb-5" />

    {/* <SportsQuickLinks className="mt-2_5 mt-lg-3_75 mb-5" /> */}

    <MostPopularGames coreProps={{ size: 200, isGamesExtendedFromCms: true }} />

    <SkipServerRender skip>
      <ProducersPromo
        coreProps={{ topProducers: TOP_PRODUCERS }}
      />
    </SkipServerRender>

    <div className="d-none d-md-block px-2 px-sm-2_5 px-lg-5 pt-0_25 mx-n2 mx-sm-n2_5 mx-lg-n5">
      <AllGames
        className="mt-5 mt-md-7 not-logged-in"
        coreProps={{ size: ALL_GAMES_DESKTOP_GAMES_COUNT }}
      />
    </div>

    <SkipServerRender skip>
      <PromoPwaInstall />
    </SkipServerRender>

    <div className="mt-5 mt-md-7">
      <EnjoyExcellentService />
    </div>

    <div className="d-md-none px-2 px-sm-2_5 px-lg-5 pt-0_25 mx-n2 mx-sm-n2_5 mx-lg-n5">
      <AllGames
        className="mb-5 mt-5 mt-md-7 not-logged-in"
        coreProps={{ size: ALL_GAMES_MOBILE_GAMES_COUNT }}
      />
    </div>

    <PromoTournaments
      coreProps={{
        availableStatuses: [
          TOURNAMENT_STATUSES.ACTIVE,
          TOURNAMENT_STATUSES.PENDING,
          TOURNAMENT_STATUSES.AWARDED,
          TOURNAMENT_STATUSES.COMPLETED,
        ]
      }}
      isHomePage
      isCardType={false}
    />

    {/* <RecommendedGames coreProps={{ size: 200 }} className="mb-3 mb-lg-5" /> */}

    {/* <GameTags className="mb-5 mt-5" /> */}

    {/* <NewestGames coreProps={{ size: 100 }} className="mb-3 mb-lg-5" /> */}

    {/* <GamesCategories className="mb-3 mb-lg-5" /> */}

    {/* <RecentlyPlayedGames coreProps={{ size: 100 }} className="mb-3 mb-lg-5" /> */}

    {/* <MostPopularGames coreProps={{ size: 200 }} className="mb-3 mb-lg-5" /> */}

    {/* <NewComersBanner /> */}

    {/* <PaymentsPromo /> */}

    {/* <PromoTournaments */}
    {/*  coreProps={{ */}
    {/*    availableStatuses: [TOURNAMENT_STATUSES.ACTIVE, TOURNAMENT_STATUSES.PENDING, TOURNAMENT_STATUSES.AWARDED] */}
    {/*  }} */}
    {/*  classNameTitle="mb-1_5 mb-lg-0 mt-8 pt-1 title font-weight-bold" */}
    {/*  isOnlySliderType */}
    {/*  isHomePage */}
    {/* /> */}

    {/* <LivechatStats /> */}
  </Fragment>
);
