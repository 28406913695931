import React from 'react';
import { FormattedTag } from 'core/components';
import { IconGames } from 'components/icons/icons-enjoy-service/icon-games';
import { IconPayment } from 'components/icons/icons-enjoy-service/icon-payment';
import { IconWithdrawal } from 'components/icons/icons-enjoy-service/icon-withdrawal';

import './enjoy-excellent-service.scss';

export const EnjoyExcellentService = () => (
  <div className="enjoy-service mt-4_25 mt-sm-8">

    <div className="d-flex mb-8 mb-md-8_5 ml-lg-n0_75">
      <div className="title-wrap text-uppercase d-flex mb-3 mb-md-5 w-100">
        <FormattedTag id="enjoy-title" className="excellent-title-desktop text-uppercase font-weight-bold text-primary mr-1_25 mr-sm-1_5 " isHtml />
        <FormattedTag id="excellent-title" className="excellent-title-desktop text-uppercase font-weight-bold  mr-1_25 mr-sm-1_5 " isHtml />
        <FormattedTag id="enjoy-exellent-title" className="excellent-title-mobile text-uppercase font-weight-bold  mr-1_25 mr-sm-1_5" isHtml />
        <FormattedTag id="service-title" className="excellent-title-mobile text-uppercase font-weight-bold  text-primary mr-1_25 mr-sm-1_5" isHtml />
        &nbsp;
      </div>
    </div>

    <div className="enjoy-service-wrapper flex-column text-white-80 font-weight-medium">
      <div className="service-item games bg-secondary-dark rounded d-flex flex-column align-items-center position-relative">
        <IconGames className="mt-n5" />

        <div className="d-flex flex-column text-center">
          <FormattedTag id="service.more-than" className="text-capitalize" isHtml />
          <span className="text-game service-item-value text-uppercase font-family-oswald">2,000</span>
          <FormattedTag id="service.games" className="text-capitalize" isHtml />
        </div>
      </div>

      <div className="service-item payment bg-secondary-dark rounded mt-6_25 mt-sm-0 d-flex flex-column align-items-center position-relative">
        <IconPayment className="mt-n5" />

        <div className="d-flex flex-column text-center">
          <FormattedTag id="title-hudge" className="text-hudge text-uppercase service-item-value font-family-oswald" isHtml />
          <FormattedTag id="service.verified.title" className="px-3 mt-0_75" isHtml />
        </div>
      </div>

      <div className="service-item withdrawal bg-secondary-dark rounded mt-6_25 mt-sm-0 d-flex flex-column align-items-center position-relative">
        <IconWithdrawal className="mt-n5" />

        <div className="d-flex flex-column text-center">
          <FormattedTag id="service.super.title" className="text-capitalize" isHtml />
          <FormattedTag id="title-fast" className="text-fast service-item-value text-uppercase font-family-oswald" isHtml />
          <FormattedTag id="service.withdrawals.title" isHtml />
        </div>
      </div>
    </div>
  </div>
);

