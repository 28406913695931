import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import TabPane from 'reactstrap/lib/TabPane';
import TabContent from 'reactstrap/lib/TabContent';
import { withUser } from 'core/hocs';
import { FormattedTag } from 'core/components';
import { TOURNAMENT_STATUSES, USER_FIELDS } from 'core/constants';

import { PromoTournamentGames } from '../promo-tournament-games/promo-tournament-games';
import { PromoTournamentTable } from '../promo-tournament-table/promo-tournament-table';

import './promo-tournament-tabs.scss';

const {
  ACTIVE,
  PENDING,
} = TOURNAMENT_STATUSES;

const tabsIDs = {
  GAMES: 'GAMES',
  AWARD_AND_RANKINGS: 'AWARD_AND_RANKINGS',
};

export class PromoTournamentTabsUI extends Component {
  static propTypes = {
    slugUrl: PropTypes.string.isRequired,
    tournamentStatus: PropTypes.string.isRequired,
    tournamentRankingList: PropTypes.arrayOf(PropTypes.shape()),
    tournamentAwards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    tournamentGames: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    tournamentRankingList: [],
    className: '',
  };

  state = {
    activeTab: tabsIDs.GAMES,
  };

  toggleTab = (e) => {
    e.preventDefault();

    const {
      currentTarget: {
        dataset: {
          tabId,
        },
      },
    } = e;

    this.setState({ activeTab: tabId });
  };

  render() {
    const {
      slugUrl,
      tournamentRankingList,
      tournamentAwards,
      tournamentGames,
      tournamentStatus,
      isUserLoggedIn,
      className,
    } = this.props;

    const { activeTab } = this.state;

    const isGamesTabActive = activeTab === tabsIDs.GAMES;
    const isAwardAndRankTabActive = activeTab === tabsIDs.AWARD_AND_RANKINGS;

    const isActiveStatus = tournamentStatus === ACTIVE;
    const isPendingStatus = tournamentStatus === PENDING;

    return (
      <div className={classNames('promo-tournament-tabs overflow-hidden mt-4 mt-sm-0 ml-sm-1', className)}>
        <div className="promo-tournament-tabs-buttons d-flex bg-blue-light p-0_5 mb-0_75">
          <FormattedTag
            tag={Button}
            id="games"
            color={isGamesTabActive ? 'white' : 'transparent'}
            data-tab-id={tabsIDs.GAMES}
            onClick={this.toggleTab}
            className={classNames('flex-grow-1 px-2 mr-0_25 text-white', {
              'is-active text-blue-dark': isGamesTabActive,
            })}
            isHtml
          />

          <FormattedTag
            tag={Button}
            // eslint-disable-next-line no-nested-ternary
            id={isActiveStatus ? 'leadeboard' : (isPendingStatus ? 'prizes' : 'result')}
            color={isAwardAndRankTabActive ? 'white' : 'transparent'}
            data-tab-id={tabsIDs.AWARD_AND_RANKINGS}
            onClick={this.toggleTab}
            className={classNames('flex-grow-1 px-2 ml-0_25 text-white', {
              'is-active text-blue-dark': isAwardAndRankTabActive,
            })}
            isHtml
          />
        </div>

        <div className="promo-tournament-tabs-content rounded bg-white p-1_25 pb-2">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={tabsIDs.GAMES}>
              <PromoTournamentGames
                coreProps={{
                  currentItem: {
                    tournamentGames,
                    description: {},
                  },
                }}
                slugUrl={slugUrl}
              />
            </TabPane>

            <TabPane tabId={tabsIDs.AWARD_AND_RANKINGS}>
              <PromoTournamentTable
                tournamentAwards={tournamentAwards}
                rankings={tournamentRankingList}
                isUserLoggedIn={isUserLoggedIn}
                status={tournamentStatus}
                slugUrl={slugUrl}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

export const PromoTournamentTabs = withUser(
  PromoTournamentTabsUI, [
    USER_FIELDS.IS_USER_LOGGED_IN,
  ],
);
